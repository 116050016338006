import Vue from 'vue';
import App from './app.vue';
import Router from 'vue-router';
import router from './router';
import store from './assets/store';
import Buefy from 'buefy';
// Not importing default style because the app contains custom color settings
// see app.vue
//import 'buefy/dist/buefy.css';

Vue.use(Buefy);
Vue.use(Router);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
