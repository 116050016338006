import {AspectRatio} from './AspectRatio';
import {MediaType} from './MediaType';

export class Media {

	constructor(uri, mediaType, aspectRatio, isPrimary) {
		this.uri = uri;
		this.type = mediaType;
		this.aspectRatio = AspectRatio.SIXTEEN_BY_NINE;
		if (aspectRatio !== null && aspectRatio !== undefined) {
			this.aspectRatio = aspectRatio;
		}

		this.primary = Boolean(isPrimary);
	}

	isImage() {
		return this.type === MediaType.IMAGE;
	}

	isMovie() {
		return this.type === MediaType.MOVIE;
	}

	isPrimary() {
		return this.primary;
	}

	getBulmaAspectRationClass() {
		return this.aspectRatio.bulmaRatio;
	}

}
