/**
 * Enum for all Judo Degrees.
 * @readonly
 * @enum {{degree: string, colour: string, name: string, ordinal: number}}
 */
export const Degree = Object.freeze({
	NONE: {degree: '', colour: '', name: 'Alle', ordinal: 100},
	/*SIXTH_KYU: {degree: '6e kyu', colour: 'wit', name: 'rok-kyu', ordinal: 1},*/
	FIFTH_KYU: {degree: '5e kyu', colour: 'geel', name: 'go-kyu', ordinal: 1},
	FOURTH_KYU: {degree: '4e kyu', colour: 'oranje', name: 'yon-kyu', ordinal: 2},
	THIRD_KYU: {degree: '3e kyu', colour: 'groen', name: 'san-kyu', ordinal: 3},
	SECOND_KYU: {degree: '2e kyu', colour: 'blauw', name: 'ni-kyu', ordinal: 4},
	FIRST_KYU: {degree: '1e kyu', colour: 'bruin', name: 'ik-kyu', ordinal: 5},
	FIRST_DAN: {degree: '1e dan', colour: 'zwart', name: 'sho-dan', ordinal: 6}
/*	SECOND_DAN: {degree: '2e dan', colour: 'zwart', name: 'ni-dan', ordinal: 7},
	THIRD_DAN: {degree: '3e dan', colour: 'zwart', name: 'san-dan', ordinal: 8},
	FOURTH_DAN: {degree: '4e dan', colour: 'zwart', name: 'yon-dan', ordinal: 9},
	FIFTH_DAN: {degree: '5e dan', colour: 'zwart', name: 'go-dan', ordinal: 10},
	SIXTH_DAN: {degree: '6e dan', colour: 'rood-wit', name: 'roku-dan', ordinal: 11},
	SEVENTH_DAN: {degree: '7e dan', colour: 'rood-wit', name: 'nana-dan', ordinal: 12},
	EIGHTH_DAN: {degree: '8e dan', colour: 'rood-wit', name: 'hachi-dan', ordinal: 13},
	NINTH_DAN: {degree: '9e dan', colour: 'rood', name: 'ku-dan', ordinal: 14},
	TENTH_DAN: {degree: '10e dan', colour: 'rood', name: 'ju-dan', ordinal: 15},
	ELEVENTH_DAN: {degree: '11e dan', colour: 'wit (brede band)', name: 'juichi-dan', ordinal: 16}*/
});
