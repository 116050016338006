import {Degree} from './Degree';
import {GoogleDriveImage} from '../media/GoogleDriveImage';
import {Movie} from '../media/Movie';
import {TechniqueType} from './TechniqueType';
import {Technique} from './Technique';

export class TechniqueList {

	static getList() {
		return [
			//
			// Leg
			//
			new Technique('O-soto-gari', TechniqueType.LEG, Degree.FIFTH_KYU, 'O-soto-gari-2.jpg', 'Grote buitenwaartse haak',
				[
					new GoogleDriveImage('o-soto-gari/IMG_8268.jpg', '1PYZqczh358KQE-URoBvaP5uhYB8-_fLd'),
					new GoogleDriveImage('o-soto-gari/IMG_8270.jpg', '1bPJTfI6zO7ePRVaYtJxD-NkJSTQYDcUo', true),
					new GoogleDriveImage('o-soto-gari/IMG_8271.jpg', '1wv6m2dfE-aKDk-5sLiKMP_7WuuIHvu1M'),
					new Movie('MOyawCpfBzs', true),
				]
			),
			new Technique('De-ashi-barai', TechniqueType.LEG, Degree.FIFTH_KYU, 'de-ashi-barai-4.jpg', 'Voorste beenveeg',
				[
					new GoogleDriveImage('de-ashi-barai/IMG_8286.jpg', '17axqZnbUYw_94FOFqxaC4-pMjsPj4qvM'),
					new GoogleDriveImage('de-ashi-barai/IMG_8287.jpg', '1R7DDk-1Dfh3OscRXyD-4ohtsRjGO5MKB', true),
					new GoogleDriveImage('de-ashi-barai/IMG_8288.jpg', '1TrY6NNh-PojhrjKjYeAVu-jrAn_WwIOT'),
					new Movie('3WvhvcGtcM4', true),
				]
			),
			new Technique('Hiza-guruma', TechniqueType.LEG, Degree.FOURTH_KYU, 'hiza-guruma-2.jpg', 'Kniewiel',
				[
					new GoogleDriveImage('hiza-guruma/IMG_8298.jpg', '1cywlQwqmJ-3N-9WRbprnfKyTgDXU4Icc'),
					new GoogleDriveImage('hiza-guruma/IMG_8299.jpg', '1A83PIZ0ff8DybPmdSqEA1uJzY9IS9h4_', true),
					new GoogleDriveImage('hiza-guruma/IMG_8300.jpg', '1evXTu8ngiMw0HvXr1rI7qFCGiFaVVoQ3'),
					new Movie('D4WbicaKnEU', true),
				]
			),
			new Technique('Ko-soto-gari', TechniqueType.LEG, Degree.FOURTH_KYU, 'Ko-soto-gari-3.jpg', 'kleine buitenwaartse haak',
				[
					new GoogleDriveImage('ko-soto-gari/IMG_8280.jpg', '1P0WYHCXwr5puw8pO1N-QSwiOu-Wz0qRj'),
					new GoogleDriveImage('ko-soto-gari/IMG_8281.jpg', '1Up1CoOls5gW6O3c1aS1cnS4GKm6w-TO1', true),
					new GoogleDriveImage('ko-soto-gari/IMG_8282.jpg', '1ZH_bctsQ9kT8NJCiRsPGRq737vhKdbZh'),
					new Movie('nHmXEbDB59U', true),

				]
			), // From Seishin List
			new Technique('Okuri-ashi-barai', TechniqueType.LEG, Degree.THIRD_KYU, 'Okuri-Ashi-barai-3.jpg', 'Beide been veeg',
				[
					new GoogleDriveImage('okuri-ashi-barai/IMG_8295.jpg', '1Q8ws7JHg7qZ8LLgawus8gLf23keUj8Xz'),
					new GoogleDriveImage('okuri-ashi-barai/IMG_8296.jpg', '1eYkloxoI9vpSH9DJEITjfGndTjQeUROg', true),
					new GoogleDriveImage('okuri-ashi-barai/IMG_8297.jpg', '15f8jA-op-is-G6Pk9fXJrZx1syeMp8lI'),
					new Movie('4E--c2otEKs', true),
				]
			),
			new Technique('O-uchi-gari', TechniqueType.LEG, Degree.FIFTH_KYU, 'O-uchi-gari-2.jpg', 'Grote binnenwaartse haak',
				[
					new GoogleDriveImage('o-uchi-gari/IMG_8274.jpg', '1aUIeBAUeYf1AStqRot-TUg6SGIA02hKN'),
					new GoogleDriveImage('o-uchi-gari/IMG_8275.jpg', '1KPsYlQKT07H1dYjWUWKnmBE3RGkpeXZz', true),
					new GoogleDriveImage('o-uchi-gari/IMG_8276.jpg', '1Ql4Dr0CtH1eBHq_tBj8WyZ4i7ek1Kh7b'),
					new Movie('hQHpPezoZBQ', true),
				]
			),
			new Technique('Ko-uchi-gari', TechniqueType.LEG, Degree.FOURTH_KYU, 'Ko-uchi-gari-3.jpg', 'Kleine binnenwaartse haak',
				[
					new GoogleDriveImage('ko-uchi-gari/IMG_8283.jpg', '1l5tneBv6kDPE_pbWsilIFbdV5L2hCHjo'),
					new GoogleDriveImage('ko-uchi-gari/IMG_8284.jpg', '1VxjlShUSr8svLRmTCOgjFzbm654d9L7z', true),
					new GoogleDriveImage('ko-uchi-gari/IMG_8285.jpg', '1ZxYvSTE2484B_C4PvJ8AZzcwxbhFydPC'),
					new Movie('mTGmOpTCbgQ', true),
				]
			),
			new Technique('O-uchi-barai', TechniqueType.LEG, Degree.SECOND_KYU, 'O-uchi-barai-1.jpg', 'Grote binnenwaartse veeg',
				[
					new GoogleDriveImage('o-uchi-barai/IMG_8289.jpg', '1ZafcT-TNBER-bL0bhoKANW-jEXoniVIO'),
					new GoogleDriveImage('o-uchi-barai/IMG_8290.jpg', '14FdC0cLLRfGPoj40vB1WKg_ZdCBJuESu', true),
					new GoogleDriveImage('o-uchi-barai/IMG_8291.jpg', '1NXxinAR4o8tx8NZMRqbpg0jOZmDGezDo'),
					new Movie('_Fy1UxE1nwc', true),
				]
			),
			new Technique('Ashi-guruma', TechniqueType.LEG, Degree.SECOND_KYU, 'Ashi-guruma-3.jpg', 'Beenwiel',
				[
					new GoogleDriveImage('ashi-guruma/IMG_8361.jpg', '1_ZliKzP1K5YTP9irUFwouBZO6UQS6TNm'),
					new GoogleDriveImage('ashi-guruma/IMG_8362.jpg', '1cdEKwtV1_f0_oqO8ZcQID8gN3XEJKH6_', true),
					new GoogleDriveImage('ashi-guruma/IMG_8363.jpg', '1FLsscC6B8KWlGIkG0KPSNBJuIyVgDpl3'),
					new GoogleDriveImage('ashi-guruma/IMG_8364.jpg', '1zYD61nsG4r2kNjts7yjb2fBoREH-ZRbz'),
					new Movie('uUkzD17Vi-w', true),
				]
			), // From Seishin List
			new Technique('Ko-uchi-barai', TechniqueType.LEG, Degree.FIRST_KYU, 'Ko-uchi-barai.jpg', 'Kleine binnenwaartse veeg',
				[
					new GoogleDriveImage('ko-uchi-barai/IMG_8292.jpg', '1_1eI8avOme_KRzvbSMoT9JAEuv1C5kCK'),
					new GoogleDriveImage('ko-uchi-barai/IMG_8293.jpg', '1tPz3W5loTocBpQ_8tS8R9ac_NpUT5Ijn', true),
					new GoogleDriveImage('ko-uchi-barai/IMG_8294.jpg', '1x6Cl1CFwfPnmZvjmXaeMpGERyqDCPuSU'),
					new Movie('DRhpuG-MtJI', true),
				]
			),
			new Technique('Sasae-tsurikomi-ashi', TechniqueType.LEG, Degree.THIRD_KYU, 'Sasae-tsuri-komi-ashi-3.jpg', 'Trillend beenblok',
				[
					new GoogleDriveImage('sasae-tsuri-komi-ashi/IMG_8301.jpg', '1-DQc1zFboHNetxA3YkdR2ZY_e_owbntk'),
					new GoogleDriveImage('sasae-tsuri-komi-ashi/IMG_8302.jpg', '1KdtYlZjUnd3uVDYHPHApbJU8zvPB4RWj', true),
					new GoogleDriveImage('sasae-tsuri-komi-ashi/IMG_8303.jpg', '1oI4KNqi_PXCpo0zq4NIdVKtsjIu-XjbK'),
					new Movie('FWec2_6YMXI', true),
				]
			), // From Seishin List
			new Technique('O-soto-guruma', TechniqueType.LEG, Degree.FIRST_KYU, null, 'Groot-buitenkant-rad)',
				[
					new GoogleDriveImage('o-soto-guruma/IMG_8374.jpg', '1hqW6ti8k6HaO3VklTqewPHn6aQLcora0'),
					new GoogleDriveImage('o-soto-guruma/IMG_8375.jpg', '1fc0j023i9cTxrv-t_MOJRM1GynqJbeoO', true),
					new GoogleDriveImage('o-soto-guruma/IMG_8376.jpg', '1nzKcVK86EzexC6bY6AfUpHrBLxQg4t1o'),
					new Movie('Q7Y2IMmSGjo', true),
				]
			),
			//
			//  END Leg
			//

			//
			// Hip
			//
			new Technique('O-goshi', TechniqueType.HIP, Degree.FIFTH_KYU, 'O-goshi-2.jpg', 'Grote heupworp',
				[
					new GoogleDriveImage('o-goshi/IMG_8312.jpg', '1WqjERYTBs_1blfN437nUZWeOJtvaxoRt'),
					new GoogleDriveImage('o-goshi/IMG_8313.jpg', '1KSx5S-xSxgWJ-xOt1mdPHiVT-uckX88i', true),
					new GoogleDriveImage('o-goshi/IMG_8314.jpg', '1-lKT_oanl52C_TNpQvtx6QMhHyny7W-2'),
					new Movie('INhOBAvfwcE', true),
				]
			),
			new Technique('Koshi-guruma', TechniqueType.HIP, Degree.FIFTH_KYU, 'Koshi-guruma-2.jpg', 'Heupwiel',
				[
					new GoogleDriveImage('koshi-guruma/IMG_8315.jpg', '1Tnavw-sBOJwUVmOkePPXS88CL1NxUJb7', true),
					new GoogleDriveImage('koshi-guruma/IMG_8316.jpg', '1YGr_6aBYUh7qc6hSbXE1bzYy1zDkncdd'),
					new GoogleDriveImage('koshi-guruma/IMG_8317.jpg', '1sd80kQOWDfsSGL4BKIbwCuC8HhpJwJSI'),
					new Movie('EjdYJaKRkio', true),
				]
			),
			new Technique('Uki-goshi', TechniqueType.HIP, Degree.FOURTH_KYU, 'Uki-goshi-2.jpg', 'Kleine heupworp',
				[
					new GoogleDriveImage('uki-goshi/IMG_8334.jpg', '1VSO7PycV5V9ZyFwG_QZ9JgNCgmIS4s-F'),
					new GoogleDriveImage('uki-goshi/IMG_8335.jpg', '1vgYDLBHkxG4G-HY75fh5Gm62AijT6a0c', true),
					new GoogleDriveImage('uki-goshi/IMG_8336.jpg', '1OQVJQx84gCSvZu70IHMm8vHO6Iyib1f5'),
					new Movie('hTYrRtnNINw', true),
				]
			),  // From Seishin List
			new Technique('Tsuri-goshi', TechniqueType.HIP, Degree.FOURTH_KYU, 'Tsuri-goshi-5.jpg', 'Optrekkende heupworp',
				[
					new GoogleDriveImage('tsuri-goshi/IMG_1977.jpg', '1sPkZqpMp0LFexhojYFfMajnLy74cOh64', true),
					new GoogleDriveImage('tsuri-goshi/IMG_1978.jpg', '1_eJTpUVP1fojFg_qcEuaQTEP4Tv_LC1U'),
					new GoogleDriveImage('tsuri-goshi/IMG_1979.jpg', '1kjrh19xuYkdE49bERtq-YyFX4DywZ7cN'),
					new Movie('koXZAqrMXR8', true),
				]
			), // From Seishin List
			new Technique('Harai-goshi', TechniqueType.HIP, Degree.THIRD_KYU, 'Harai-goshi-4.jpg', 'Veeg heupworp',
				[
					new GoogleDriveImage('harai-goshi/IMG_8319.jpg', '1_3ZcoanO0cpll1BxZ7doCofhuS0gT07d'),
					new GoogleDriveImage('harai-goshi/IMG_8320.jpg', '1h0kUGmZ1gcvgxv9TWLxiXPeNfKNptPwk', true),
					new GoogleDriveImage('harai-goshi/IMG_8321.jpg', '1GSzZWZtQnM2nR7yiSYMNbsjxQpIlHtVw'),
					new GoogleDriveImage('harai-goshi/IMG_8322.jpg', '1cJJdSeLh1iUym66rKrymT7c8ZkkhhLYU'),
					new Movie('57vtoSSaDpA', true),
				]
			),
			new Technique('Tsurikomi-goshi', TechniqueType.HIP, Degree.THIRD_KYU, 'Tsuri-komi-goshi-3.jpg', 'Trillende heupworp',
				[
					new GoogleDriveImage('tsuri-komi-goshi/IMG_8323.jpg', '1k5cEDdGO1iFrRAbmYk1uq1qf4VKLIH_n'),
					new GoogleDriveImage('tsuri-komi-goshi/IMG_8324.jpg', '17EoliddL7_AUhl258TyB4ucIG4o-Nk7e', true),
					new GoogleDriveImage('tsuri-komi-goshi/IMG_8325.jpg', '1ESz2sIeBN2r-FH3BBVOsLKyTcFk-i6cf'),
					new Movie('j5QybAswaP0', true),
				]
			),
			new Technique('Hane-goshi', TechniqueType.HIP, Degree.SECOND_KYU, 'Hane-goshi-3.jpg', 'Vleugel heupworp',
				[
					new GoogleDriveImage('hane-goshi/IMG_8331.jpg', '1hTln9_fLWjHA32YymC3LqfNlXMLyIY4r', true),
					new GoogleDriveImage('hane-goshi/IMG_8332.jpg', '14Mwv0RxnplUfE7DywIVu2slk85I-vR0n'),
					new GoogleDriveImage('hane-goshi/IMG_8333.jpg', '1Vu9lC33qQlf1E2IvdWJWcfNQ0jB5wViw'),
					new Movie('un8rG8fc9lA', true),
				]
			), // From Seishin List
			new Technique('Uchi-mata', TechniqueType.HIP, Degree.FIRST_KYU, 'Uchi-mata-3.jpg', 'Binnen dijbeen',
				[
					new GoogleDriveImage('uchi-mata/IMG_8327.jpg', '1zJvRHV6Q356EsFy9dSOylBBLxB1Va5_p'),
					new GoogleDriveImage('uchi-mata/IMG_8328.jpg', '1sO4Yu5z7AN8Vjcuam0AirN2UNJKUqCWg', true),
					new GoogleDriveImage('uchi-mata/IMG_8329.jpg', '1-1EEpO8k3vJ1PwFCcx_ke5OEI_l4ouWB'),
					new GoogleDriveImage('uchi-mata/IMG_8330.jpg', '1Xw4UwYjthAfStf2sOnIQPDC2pqgMqNma'),
					new Movie('c95ls3zsXww', true),
				]
			),
			new Technique('Utsuri-goshi', TechniqueType.HIP, Degree.FIRST_KYU, null, 'Wisselende heupworp',
				[
					new GoogleDriveImage('utsuri-goshi/IMG_8377.jpg', '1B8x3XnQ04UqFGEfdoMyv3cYg1Z7jvMr4'),
					new GoogleDriveImage('utsuri-goshi/IMG_8378.jpg', '1mrCA030kH5HUXc1dNLI4aykgBUylNJCf', true),
					new GoogleDriveImage('utsuri-goshi/IMG_8379.jpg', '1RXEyO3vCC3IYBEpkBiR-TovJKdWu4-OD'),
					new GoogleDriveImage('utsuri-goshi/IMG_8380.jpg', '111o44EntUq201JOcKOVxqKUjKoQrb5Y3'), // TODO image has a black stripe ??
					new Movie('bwiGiVIaYYw', true),
				]
			), // From Seishin List

			//
			// END Hip
			//

			//
			// Shoulder
			//
			new Technique('Ippon-seoi-nage', TechniqueType.SHOULDER, Degree.FIFTH_KYU, 'Ippon-seoi-nage-2.jpg', 'Spierballen worp',
				[
					new GoogleDriveImage('ippon-seoi-nage/IMG_8304.jpg', '1gx6GG_mMvlIG1D0eRUTQCJCEjCcBjPkY'),
					new GoogleDriveImage('ippon-seoi-nage/IMG_8305.jpg', '1zFJT9P4owDYkxe4wRTkOYxRAaQUYpODz'),
					new GoogleDriveImage('ippon-seoi-nage/IMG_8306.jpg', '1xCwD1Myvjv9dQAi5BOPgRVzjNnDsGa43', true),
					new GoogleDriveImage('ippon-seoi-nage/IMG_8307.jpg', '1ZbhuDf_U-l0h5VzKo-NXCo7xbwbHoAeY'),
					new GoogleDriveImage('ippon-seoi-nage/IMG_8308.jpg', '1N90mrnLrEyIKhNrUAYvHMfavsV3gaK7N'),
					new Movie('cMpDrYiz1XQ', true),
				]
			),
			new Technique('Morote-seoi-nage', TechniqueType.SHOULDER, Degree.FOURTH_KYU, 'Seoi-nage-3.jpg', 'Dubbelzijdige schouderworp',
				[
					new GoogleDriveImage('marote-seoi-nage/IMG_8304.jpg', '1kBrpMMZG77j0clb4Toe6quB9dcC91HkC'),
					new GoogleDriveImage('marote-seoi-nage/IMG_8309.jpg', '1S49M74Cc11Pw1_GzHrYwYLoTH27ZAkSM', true),
					new GoogleDriveImage('marote-seoi-nage/IMG_8310.jpg', '1mjkBA9peGmh1eV8l6fhiCnvCt6sGMp11'),
					new GoogleDriveImage('marote-seoi-nage/IMG_8311.jpg', '1gQnvZoMbf22PDHHUnJ6gh06_GnT9QEN_'),
					new Movie('TfItbN6DgV0', true),
				]
			),
			new Technique('Kata-guruma', TechniqueType.SHOULDER, Degree.FIRST_KYU, null, 'Schouder-rad',
				[
					new GoogleDriveImage('kata-guruma/IMG_1980.jpg', '1EKdtZonrcsVy3njhiqGqa_J7rXj1dvUN'),
					new GoogleDriveImage('kata-guruma/IMG_1981.jpg', '1YIiy4xGrLD_ar_ypV_tmqyrgPjHRjDcy', true),
					new GoogleDriveImage('kata-guruma/IMG_1982.jpg', '1qZhCzdEnModtj32oeX_kLHyNORPK2Yk_'),
					new Movie('CWn1dECSDN0', true),
				]
			),
			//
			// END Shoulder
			//

			//
			// Arm
			//
			new Technique('Tai-otoshi', TechniqueType.ARM, Degree.FOURTH_KYU, 'Tai-otoshi-3.jpg', 'Lichaam neervallen',
				[
					new GoogleDriveImage('tai-otoshi/IMG_8337.jpg', '142PhjpezlNsIVtBQwrq1rMCD0MZUGyHJ'),
					new GoogleDriveImage('tai-otoshi/IMG_8338.jpg', '1TE4rQJOXMXTGfH89q0qLtnLzh6KQIp9B', true),
					new GoogleDriveImage('tai-otoshi/IMG_8339.jpg', '1csgJZSMHnqLUVWI3kUaVsKopi6eHbm4-'),
					new GoogleDriveImage('tai-otoshi/IMG_8340.jpg', '16chdJDnOoaC-_xcdowuKG_o1P27KX9cr'),
					new Movie('kK74nGXn2a4', true),
				]
			),
			new Technique('Uki-otoshi', TechniqueType.ARM, Degree.SECOND_KYU, 'Uki-otoshi-3.jpg', 'Zweeftrein',
				[
					new GoogleDriveImage('uki-otoshi/IMG_8341.jpg', '1-24Fd1YsLa4hNbTvqLwpcCr22kqX3Lxk'),
					new GoogleDriveImage('uki-otoshi/IMG_8342.jpg', '1nG2dcJCXwQAiQVRITx_ovtVV0loud7fT'),
					new GoogleDriveImage('uki-otoshi/IMG_8343.jpg', '1tQOAmgTtlxd9ECkWCClxKs4sqbAY_BAU'),
					new GoogleDriveImage('uki-otoshi/IMG_8344.jpg', '1WJOGICtsSUoLoYFOUrjUFYqb5vYH6VmM', true),
					new GoogleDriveImage('uki-otoshi/IMG_8345.jpg', '1qDwLE46lyByNyK6J-18WhQf23_PS6aJL'),
					new Movie('FwAAQGQP3TQ', true),
					new Movie('YpNxDZ9i4L4'),
				]
			),  // From Seishin List
			//
			// END Arm
			//


			//
			// Sacrifice
			//
			new Technique('Tomoe-nage', TechniqueType.SACRIFICE, Degree.FOURTH_KYU, 'Tomoe-nage-3.jpg', 'Boogworp',
				[
					new GoogleDriveImage('tomoe-nage/IMG_8346.jpg', '1rrkEqhQR6_z_LNzYfgodh7I9kxSRpZPv'),
					new GoogleDriveImage('tomoe-nage/IMG_8347.jpg', '14ZStWTOBt8nCdxcSXKj_KeyMkKqFixfl', true),
					new GoogleDriveImage('tomoe-nage/IMG_8348.jpg', '1BiLm83GALr4OGGJJ58QJwW6kl9qs7oX6'),
					new GoogleDriveImage('tomoe-nage/IMG_8349.jpg', '1NdaIkZLU-gjg9E-UqJ9vmFNgj6OQ6NKv'),
					new Movie('LskLiO2TTrU', true),
				]
			),
			new Technique('Tani-otoshi', TechniqueType.SACRIFICE, Degree.FOURTH_KYU, 'Tani-otoshi-3.jpg', 'Dal neervallen',
				[
					new GoogleDriveImage('tani-otoshi/IMG_8358.jpg', '1D2qfcYbAlMLMX7L6kb7i_Y8zP_5JhRu1'),
					new GoogleDriveImage('tani-otoshi/IMG_8359.jpg', '1VhhIP5Wz9oUhGSV9W754a17GamIYU-ug', true),
					new GoogleDriveImage('tani-otoshi/IMG_8360.jpg', '1l4V6OMFnCuItxwrESrc7p5r5izsoejNK'),
					new Movie('FlFo9gQypHY', true),
				]
			),
			new Technique('Sumi-gaeshi', TechniqueType.SACRIFICE, Degree.FIRST_KYU, 'Sumi-gaeshi-3.jpg', 'Gehoekte tegenworp',
				[
					new GoogleDriveImage('sumi-geashi/IMG_8350.jpg', '1JCDiO1DxVOgxKp30hcIm8kU8TPztKWhg'),
					new GoogleDriveImage('sumi-geashi/IMG_8351.jpg', '1tAubYYOJw9rhGb51wNG8ujOxZB7HO3DR', true),
					new GoogleDriveImage('sumi-geashi/IMG_8352.jpg', '1bEw6WJnJNeAjythkMVMkI9zRBS1qFeE8'),
					new GoogleDriveImage('sumi-geashi/IMG_8353.jpg', '1G__exGv_o-zMNxfcDuGlwRvV9qmk7f9j'),
					new Movie('o0lDSYyFw0o', true),
				]
			),
			new Technique('Yoko-tomoe-nage', TechniqueType.SACRIFICE, Degree.FIRST_KYU, 'Yoko-tomoe-nage.jpg', 'Zijwaartse boogworp',
				[
					new GoogleDriveImage('yoko-tomoe-nage/IMG_8354.jpg', '1vLKjyxNz5wUFiPW62AzZFu59OpKdWS58'),
					new GoogleDriveImage('yoko-tomoe-nage/IMG_8355.jpg', '1SRQhI_W_ZspPvsUklX3DUZXwW2q2hsZY', true),
					new GoogleDriveImage('yoko-tomoe-nage/IMG_8356.jpg', '1WEwZn47jtzsTxZ7piTq1X5E7AshK7HAl'),
					new GoogleDriveImage('yoko-tomoe-nage/IMG_8357.jpg', '1tfldF1q88o6DJAdh9-8oRU5dfwEgz6R5'),
					new Movie('Q8CO1PPVgTY', true),
				]
			),
			new Technique('Uki-waza', TechniqueType.SACRIFICE, Degree.FIRST_KYU, 'Uki-waza-4.jpg', 'Drijvende techniek',
				[
					new GoogleDriveImage('uki-waza/IMG_8365.jpg', '1TjG1MRoTtcLv1cGEEnhOaIjhIieSp28C'),
					new GoogleDriveImage('uki-waza/IMG_8366.jpg', '1qjBq1ur-As-CjckeVFYP5GyUTetBbN-E', true),
					new GoogleDriveImage('uki-waza/IMG_8367.jpg', '1y_CHaYhwm5JOCkhXYk7QcLbj3vM8GncC'),
					new GoogleDriveImage('uki-waza/IMG_8368.jpg', '1IMOF0203AInHvGYQHHku5FSj7HXMq9t9'),
					new Movie('R3BaV8Tv1NA', true),
				]
			), // From Seishin List
			new Technique('Ura-nage', TechniqueType.SACRIFICE, Degree.FIRST_KYU, null, 'Ruggelings-werpen',
				[
					new GoogleDriveImage('ura-nage/IMG_8381.jpg', '11zcIKBpqsLe9ghi7NT_xEf89kbkhlB8z'),
					new GoogleDriveImage('ura-nage/IMG_8382.jpg', '1uY4ddDATE39d_IL-NMsqWNr72NVpJLnB', true),
					new GoogleDriveImage('ura-nage/IMG_8383.jpg', '15-Z3_68cBfq5VP8qzU0NYwqrv4Phy151'),
					new Movie('otrgYm4L89g', true),
				]
			), // From Seishin List
			new Technique('Yoko-wakare', TechniqueType.SACRIFICE, Degree.FIRST_KYU, null, 'Zijwaarts-scheiden',
				[
					new GoogleDriveImage('yoko-wakare/IMG_8371.jpg', '16LuGN5rm9-FNr2RvBcjWXvn4glA1KcNg'),
					new GoogleDriveImage('yoko-wakare/IMG_8372.jpg', '1G74eZn2Sde7duA9RZ5Lvf4y4tAryVilC', true),
					new GoogleDriveImage('yoko-wakare/IMG_8373.jpg', '1FQBK4UXJN_Yq4pslzcS3sG7-Fvre1UOv'),
					new Movie('RigzLtlrYQA', true),
				]
			), // From Seishin List
			new Technique('Yoko-guruma', TechniqueType.SACRIFICE, Degree.FIRST_KYU, null, 'Zijwaarts-rad',
				[
					new GoogleDriveImage('yoko-guruma/IMG_1989.jpg', '1gxJR-PMuQv00l5AWv2umZ2UrdRh3ePp4', true),
					new GoogleDriveImage('yoko-guruma/IMG_1987.jpg', '1NJDI-0upburk01YYLyc7YEPxhYpqDOap'),
					new GoogleDriveImage('yoko-guruma/IMG_1988.jpg', '1kJeRBzwWZEpmgmJ8L06f4JvZqW7OANvF'),
					new Movie('6w80bGf7ld8', true),
			]),
			new Technique('Yoko-gake', TechniqueType.SACRIFICE, Degree.FIRST_KYU, null, 'Zijwaarts-haken',
				[
					new GoogleDriveImage('yoko-gake/IMG_1983.jpg', '1kRL11dtpW22CeJEjili7d_RrZoFCo8Y_', ),
					new GoogleDriveImage('yoko-gake/IMG_1984.jpg', '1EErLTUQg0h9LMUY1gMfsIgxXAmDwv6Iu', true),
					new GoogleDriveImage('yoko-gake/IMG_1985.jpg', '1H1Hpe3zI4JsIK5WNoIf0Z0cQmR0nSN6-'),
					new Movie('64GHWTWtvX4', true),
			]),

			//
			// Sacrifice
			//

			//
			// Pinning
			//
			new Technique('Kesa-gatame', TechniqueType.PINNING, Degree.FIFTH_KYU, 'Kesa-gatame.jpg', 'Diagonaal-insluiten',
				[
					new GoogleDriveImage('houdgrepen/IMG_8096.jpg', '1TnqcfcHOzMUOfcGd0kOSAe57mMM424xH', true),
					new GoogleDriveImage('houdgrepen/IMG_8106.jpg', '1rIVh7-VxLoYSLqq5t5GCXWdlDKC4Q01R'),
				]
			),
			new Technique('Kata-gatame', TechniqueType.PINNING, Degree.FIFTH_KYU, 'Kata-gatame.png', 'Schouder-insluiten',
				[
					new GoogleDriveImage('houdgrepen/IMG_8099.jpg', '1xEJqt93L55T_L_y8p_qMAaJM1XPIF9xr', true),
					new GoogleDriveImage('houdgrepen/IMG_8108.jpg', '1cQLjMESbegrWr5hUPy-T1UsIny1qXyqO'),
					new GoogleDriveImage('houdgrepen/IMG_8098.jpg', '1Me-zZc4DjEFNv1ntw3sXIoyHXjeTb0B2'),
				]
			),
			new Technique('Mune-gatame', TechniqueType.PINNING, Degree.FIFTH_KYU, 'mune-gatami.gif', 'Borst-insluiten',
				[
					new GoogleDriveImage('houdgrepen/IMG_8101.jpg', '19-AwdESbdPKubUBd-RWJkJ2g7I3zZWlT', true),
				]
			), // From Seishin List
			new Technique('Yoko-shiho-gatame', TechniqueType.PINNING, Degree.FIFTH_KYU, 'Yoko-shiho-gatame.jpg', 'Zijwaarts-vier richtingen-insluiten',
				[
					new GoogleDriveImage('houdgrepen/IMG_8102.jpg', '12OwExnIsZ-X0J33MRHa7GY37KpdvB2DN', true),
					new GoogleDriveImage('houdgrepen/IMG_8111.jpg', '1pSPss1efaK_uICb3SnNuWNXFqyzVdJCU'),
				]
			),
			new Technique('Kami-shiho-gatame', TechniqueType.PINNING, Degree.FIFTH_KYU, 'Kami-shiho-gatame.jpg', 'Boven-vier richtingen-insluiten',
				[
					new GoogleDriveImage('houdgrepen/IMG_8112.jpg', '1BtKwaecWUxM233P3K-JXm-1yu4z6sOdj', true),
					new GoogleDriveImage('houdgrepen/IMG_8103.jpg', '1JS012QEvNDIyJpHrktzSvdDIaSAr_zJd'),
				]
			),
			new Technique('Tate-shiho-gatame', TechniqueType.PINNING, Degree.FOURTH_KYU, 'Tate-shiho-gatame.jpg', 'Verticaal-vier richtingen-insluiten',
				[
					new GoogleDriveImage('houdgrepen/IMG_8100.jpg', '1wU0VI_b_E3GSTc1P_Q2yiSlbFr_ck6Bq', true),
					new GoogleDriveImage('houdgrepen/IMG_8109.jpg', '1CFmmrWipaM3BnBojOHp3siNCX8jCfR58'),
				]
			),
			new Technique('Gyaku-kesa-gatame', TechniqueType.PINNING, Degree.THIRD_KYU, 'Gyaku-kesa-gatame.gif', 'Verdraaid-diagonaal-insluiten',
				[
					new GoogleDriveImage('houdgrepen/IMG_8113.jpg', '1n1HdnmJDjmi-YPbUQGmjzYKTTjrwcsvE', true),
				]
			),  // From Seishin List
			//
			// END Pinning
			//

			//
			// Arm locks
			//
			new Technique('Juji-gatame', TechniqueType.ARM_LOCK, Degree.FOURTH_KYU, 'Juji-gatame.jpg', 'Gekruiste controle',
				[
					new GoogleDriveImage('armklemmen/IMG_8114.jpg', '15AKbI0eDF-ADXANmKaRoiyTQ_yw7bac4', true),
				]
			),
			new Technique('Ude-garami', TechniqueType.ARM_LOCK, Degree.FOURTH_KYU, 'Ude-garami.jpg', 'Armverdraaiing',
				[
					new GoogleDriveImage('armklemmen/IMG_8115.jpg', '1OUFHkQ05Riwp-W9bfn0yELq4KOzjpwGp', true),
					new GoogleDriveImage('armklemmen/IMG_8116.jpg', '1aBZFQjJ5Dg2cn_d5pAKye9ryP8RePn3F'),
				]
			),
			new Technique('Ude-gatame', TechniqueType.ARM_LOCK, Degree.FOURTH_KYU, 'Ude-gatame.gif', 'Armcontrole',
				[
					new GoogleDriveImage('armklemmen/IMG_8123.jpg', '11Q7qj8Jxy7t_qNIu53Hthg-O0fbmkXRt', true),
				]
			),
			new Technique('Hiza-gatame', TechniqueType.ARM_LOCK, Degree.THIRD_KYU, 'Hiza-gatame.gif', 'Kniecontrole',
				[
					new GoogleDriveImage('armklemmen/IMG_8119.jpg', '1bdFOfBD9hDQlMFf_8CPPoELdfzcZPdCM', true),
					new GoogleDriveImage('armklemmen/IMG_8118.jpg', '1cpc09CiH3d_hHX3Fe3ZypTUj89QDZ-Eo'),
					new GoogleDriveImage('armklemmen/IMG_8117.jpg', '1C0kGOPVjKXBQGud0yrBcnoQ291c7U4nu'),
				]
			),
			new Technique('Waki-gatame', TechniqueType.ARM_LOCK, Degree.SECOND_KYU, 'Waki-gatame.jpg', 'Oksel / flankcontrole',
				[
					new GoogleDriveImage('armklemmen/IMG_8120.jpg', '17zKDk623gOAzjQ-ZJA-j0chIqN_ERWEk', true),
				]
			),
			//
			// END Arm locks
			//

			//
			// Chokes
			//
			new Technique('Kata-juji-jime', TechniqueType.CHOKE, Degree.FOURTH_KYU, 'Kata-juji-jime.gif', 'Eenzijdige gekruiste verwurging',
				[
					new GoogleDriveImage('verwurgingen/IMG_8140.jpg', '13DhtupmsSsf7dpBXfNxe41i1zevn2N5c', true),
				]
			),
			new Technique('Nami-juji-jime', TechniqueType.CHOKE, Degree.FOURTH_KYU, 'Nami-juji-jime.gif', 'Gewone gekruiste verwurging',
				[
					new GoogleDriveImage('verwurgingen/IMG_8138.jpg', '1Q04QP865X8xYN2qGxzK-Pmm-rdcxzzwT', true),
				]
			),
			new Technique('Hadaka-jime', TechniqueType.CHOKE, Degree.FOURTH_KYU, 'Hadaka-jime.gif', 'Naakte verwurging',
				[
					new GoogleDriveImage('verwurgingen/IMG_8124.jpg', '1cTUYbG_ka8F_tOBl-Xri_I4aulS66qdd', true),
					new GoogleDriveImage('verwurgingen/IMG_8128.jpg', '1IDquf6Go9ZezQcNe-IY05PEYiiD_PFiC'),
				]
			),
			new Technique('Okuri-eri-jime', TechniqueType.CHOKE, Degree.FOURTH_KYU, 'Okuri-eri-jime.png', 'Beide revers verwurging',
				[
					new GoogleDriveImage('verwurgingen/IMG_8126.jpg', '1xLorWP8nBOMo5Cxi8aH-niW5Ylvfz7mk', true),
				]
			),
			new Technique('Morote-jime', TechniqueType.CHOKE, Degree.THIRD_KYU, 'Morote-jime.gif', 'Tweehandige verwurging',
				[
					new GoogleDriveImage('verwurgingen/IMG_8136.jpg', '1G0cVC4ZHNCXlueA3aGztLVixxNQ6taU4', true),
				]
			),
			new Technique('Gyaku-juji-jime', TechniqueType.CHOKE, Degree.THIRD_KYU, 'Gyaku-juji-jime.gif', 'Omgekeerde gekruiste verwurging',
				[
					new GoogleDriveImage('verwurgingen/IMG_8137.jpg', '1eeigPCTxV58ITS0sTNYZF0-B9RPIw2Gv', true),
				]
			),
			new Technique('Sankaku-jime', TechniqueType.CHOKE, Degree.FOURTH_KYU, 'Sankaku-jime.png', 'Onderliggende driehoeks verwurging',
				[
					new GoogleDriveImage('verwurgingen/IMG_8135.jpg', '1NFSs97j78f9W9tgNXLdDm8STAHSVlFKR', true),
				]
			),
			new Technique('Ushiro-sankaku-jime', TechniqueType.CHOKE, Degree.THIRD_KYU, 'Ushiro-sankaku-jime.png', 'Achterwaartse driehoeks verwurging',
				[
					new GoogleDriveImage('verwurgingen/IMG_8132.jpg', '1eckHX3mRKqAbRPKq9SslSOiIQL5eCmaF', true),
				]
			),
			new Technique('Yoko-sankaku-jime', TechniqueType.CHOKE, Degree.THIRD_KYU, 'Yoko-sankaku-jime.png', 'Zijwaartse driehoeks verwurging',
				[
					new GoogleDriveImage('verwurgingen/IMG_8130.jpg', '1OCApdi80UwEu_qIhYzAc_zSsZl76H_Vx', true),
					new GoogleDriveImage('verwurgingen/IMG_8131.jpg', '1_SnOWnDfcqAzyjHsPat34vXqQ9N8Rqe1'),
				]
			),
			new Technique('Kata-te-jime', TechniqueType.CHOKE, Degree.FIRST_KYU, 'Kata-te-jime.png', 'Eenzijdige handverwurging',
			[
					new GoogleDriveImage('verwurgingen/IMG_2011.jpg', '1mxQC2tNYXuMJq5HnurUJBD7LE0K8rPJ6'),
					new GoogleDriveImage('verwurgingen/IMG_2012.jpg', '1Q5Pp-HavJLuXMdPLedtzItdwgb0EwDVN'),
					new GoogleDriveImage('verwurgingen/IMG_2013.jpg', '1c-IzdFYlU6fsW4dvaEEkQ9Xnqhbijcbw', true),
					new Movie('eghztQQkoWk', true)
				]
			),
			new Technique('Kata-ha-jime', TechniqueType.CHOKE, Degree.FIRST_KYU, 'Kata-ha-jime.gif', 'Eenzijdige vleugel verwurging',
				[
					new GoogleDriveImage('verwurgingen/IMG_8126.jpg', '1xLorWP8nBOMo5Cxi8aH-niW5Ylvfz7mk'),
					new GoogleDriveImage('verwurgingen/IMG_8125.jpg', '1Vq4k3_aU08gv0wwwZW9moBQyuE39XI5A', true),
				]
			),
			new Technique('Tsukomi-jime', TechniqueType.CHOKE, Degree.FIRST_KYU, 'Tsukomi-jime.png', 'Duwende verwurging',
				[
					new GoogleDriveImage('verwurgingen/IMG_2014.jpg', '19DQ8YXMVTtFP0UOHyfxLiEKsZKNG69VC'),
					new GoogleDriveImage('verwurgingen/IMG_2015.jpg', '1-Na75BrtTQaA4WD7O4Ou2UnGRoUVS35l', true),
					new Movie('v3aEYhKl5H8', true)
				]
			),
			//
			// END Chokes
			//
		];
	}
}
