import {Media} from './Media';
import {MediaType} from './MediaType';

export class Image extends Media {

	constructor(uri, isPrimary, aspectRatio) {
		const baseImagePath = "assets/media/";
		if (!uri.match(/assets\/media.*/i)) {
			uri = baseImagePath + uri;
		}
		super(uri, MediaType.IMAGE, aspectRatio, isPrimary);
	}

	getImageUri() {
		return this.uri;
	}
}
