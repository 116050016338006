import {TechniqueType} from './TechniqueType';

export class Technique {

	constructor(name, type, degree, image, dutchName, mediaList) {
		this.name = name;
		this.type = type;
		this.degree = degree;
		this.image = image;
		this.dutchName = dutchName;
		this.mediaList = Array.isArray(mediaList) ? mediaList : [];
	}

	getImage() {
		let image = this.image != null ? this.image : 'Seishin_Budo_Logo.jpg';
		return 'assets/images/' + image;
	}

	getPrimaryImage() {
		const images = this.mediaList.filter(item => {
			return item.isImage() && item.isPrimary();
		});

		if (Array.isArray(images)) {
			return images[0];
		}

		return this.getImage();
	}

	getPrimaryImageUri() {
		const primaryImage = this.getPrimaryImage();
		if (primaryImage.uri === undefined) {
			return primaryImage;
		}
		return primaryImage.uri;
	}

	getImageList() {
		return this.mediaList.filter(item => {
			return item.isImage();
		});
	}

	getFirstMovie() {
		const movies = this.mediaList.filter(item => {
			return item.isMovie();
		});
		return Array.isArray(movies) ? movies[0] : null;
	}

	hasMovie() {
		return Boolean(this.getFirstMovie());
	}

	/**
	 * Any technique except for armlocks and chokes are safe for kids.
	 */
	isSafeForKids() {
		return this.type !== TechniqueType.ARM_LOCK &&
			this.type !== TechniqueType.CHOKE &&
			this.type !== TechniqueType.SACRIFICE;
	}
}
