<template>
  <div class="content">
    <div class="columns is-mobile">
      <div class="column is-one-third-mobile">
        <b-dropdown v-model="selectedDegreeType" aria-role="list" hoverable>
          <button slot="trigger" class="button is-info">
            <span v-if="selectedDegreeType.ordinal === 100">Kleur band</span>
            <span v-else>{{ selectedDegreeType.colour }} ({{ selectedDegreeType.degree }})</span>
            <b-icon icon="menu-down"></b-icon>
          </button>

          <b-dropdown-item v-for="(option, index) in degreeTypes"
                           :key="index"
                           :value="option"
                           aria-role="listitem"
                           v-on:click="restartTraining">

            <template v-if="option.ordinal < 100">
              {{ option.colour }} ({{ option.degree }})
            </template>
            <template v-else>
              {{ option.name }}
            </template>
          </b-dropdown-item>
        </b-dropdown>

      </div>
      <div class="column is-one-third-mobile">
        <b-switch v-model="isKidsMode" type="is-info">
          Kids modus
        </b-switch>
      </div>
      <div class="column is-one-third-mobile">
        <b-switch v-model="isQuestionByFlashCard" type="is-info">
          Vragen modus
        </b-switch>
      </div>

    </div>
    <div class="columns is-mobile notification">
      <div class="column is-hidden-mobile">
        <p class="subtitle"><strong>Score</strong></p>
      </div>
      <div class="column is-one-third-mobile">vragen: {{ score.getTotal() }}</div>
      <div class="column is-one-third-mobile">goed: {{ score.totalCorrect }}</div>
      <div class="column is-one-third-mobile">Fout: {{ score.totalInCorrect }}</div>
    </div>

    <div>&nbsp;</div>

    <!---------------------------------------------------------------------
      Question start
    ----------------------------------------------------------------------->

    <div class="columns is-mobile notification is-centered">
      <div class="column"></div>
      <div v-if="!isQuestionByFlashCard"
           class="column is-centered column is-full-mobile is-half-tablet is-half-desktop is-half-widescreen is-half-fullhd">
        <p class="subtitle has-text-weight-bold has-text-centered">
          Hoe heet deze techniek?
        </p>
        <training-carousel
            :autoplay=false
            :is-hidden="true"
            :judoTechnique=question.judoTechnique />
      </div>

      <div v-else class="column is-full-mobile">
        <p class="subtitle has-text-weight-bold has-text-centered">
          Welk plaatje hoort bij deze techniek?
        </p>
        <p class="subtitle has-text-centered">
          {{ question.judoTechnique.name }}
        </p>
      </div>
      <div class="column"></div>
    </div>


    <!---------------------------------------------------------------------
      Question end
    ----------------------------------------------------------------------->

    <div class="columns is-mobile">
      <div class="column">
        <p v-if="!isHidden" class="subtitle has-text-centered">
        {{ isCorrect ? 'Goed zo!' : 'Helaas nog even oefenen' }}
        </p>
        <p v-else class="subtitle">&nbsp;</p>
      </div>
    </div>

    <!---------------------------------------------------------------------
      Answer start
    ----------------------------------------------------------------------->

    <div class="columns is-mobile">
      <div class="column notification" v-bind:class="answerBackgroundColor">

        <template v-if="!isQuestionByFlashCard">

          <template v-for="answer in shuffledAnswers">
            <div class="columns">
              <div class="column has-text-centered">
                <b-button :answer-id="answer.judoTechnique.name"
                          expanded
                          type="is-info"
                          v-on:click="checkAnswer(answer)">

                  <template v-if="!isHidden">
                      <span v-if="answer.isCorrect" class="icon has-text-success">
                        <em class="fas fa-check-square"></em>
                      </span>
                    <span v-else class="icon has-text-danger">
                        <em class="fas fa-ban"></em>
                      </span>
                  </template>

                  {{ answer.judoTechnique.name }}
                </b-button>
              </div>
            </div>
          </template>
        </template>

        <template v-else>
          <div class="columns">
            <div v-for="answer in shuffledAnswers" class="column has-text-centered">
              <training-carousel
                  :autoplay=false
                  :card-color="answerBackgroundColor"
                  :is-correct="answer.isCorrect"
                  :is-hidden="isHidden"
                  :judoTechnique=answer.judoTechnique
                  @onCheckAnswer="checkAnswer(answer)" />
            </div>
          </div>
        </template>

      </div>
    </div>
    <!---------------------------------------------------------------------
      Answer end
    ----------------------------------------------------------------------->

    <div>&nbsp;</div>

    <div class="columns is-mobile notification">
      <div class="column is-full-mobile">
        <article>

            <b-button outlined
                      type="is-info"
                      v-on:click="nextQuestion">
              Volgende
            </b-button>
            <span v-if="hasForgottenToAnswer" class="has-text- has-text-danger">
              Geen antwoord gekozen!
            </span>

        </article>
      </div>
    </div>
  </div>
</template>

<script>
import {Training} from '../../assets/js/training/Training';
import {ArrayUtil} from '../../assets/js/ArrayUtil';
import {TechniqueList} from '../js/judo/TechniqueList';
import {Degree} from '../js/judo/Degree';
import {Score} from '../js/training/Score';
import TrainingCarousel from './TrainingCarousel';

const answerAmount = 3;

export default {
  components: {
    'training-carousel': TrainingCarousel
  },
  name: 'TrainingOverview',
  data() {
    return {
      answerAmount: answerAmount,
      answers: [],
      training: new Training(answerAmount, TechniqueList.getList()),
      isHidden: true,
      isCorrect: false,
      hasAnsweredQuestion: false,
      hasForgottenToAnswer: false,
      techs: TechniqueList.getList(),
      score: new Score(0, 0)
    };
  },
  computed: {
    isQuestionByFlashCard: {
      get() {
        return this.$store.getters['training/isQuestionByFlashCard'];
      },
      set(value) {
        this.$store.dispatch('training/updateQuestionByFlashCard', value);
        this.restartTraining();
      }
    },
    isKidsMode: {
      get() {
        return this.$store.getters['training/isKidsMode'];
      },
      set(value) {
        this.$store.dispatch('training/updateKidsMode', value);
        this.restartTraining();
      }
    },
    selectedDegreeType: {
      get() {
        return this.$store.getters['training/selectedDegreeType'];
      },
      set(value) {
        this.$store.dispatch('training/updateSelectedDegreeType', value);
      }
    },
    degreeTypes() {
      return Degree;
    },
    shuffledAnswers() {
      this.answers = this.training.question.getAnswers();
      ArrayUtil.shuffleArray(this.answers);
      return this.answers;
    },
    question() {
      return this.training.question.question;
    },
    answerBackgroundColor() {
      if (this.isHidden) {
        return '';
      }
      return this.isCorrect ? 'is-success' : 'is-danger';
    }
  },
  methods: {
    checkAnswer(answer) {
      if (!this.isHidden) {
        return;
      }
      this.hasAnsweredQuestion = answer !== undefined;
      this.training.question.answeredCorrect = answer.isCorrect;
      this.isHidden = false;
      this.isCorrect = answer.isCorrect;
      this.hasForgottenToAnswer = false;
    },
    nextQuestion() {
      if (!this.hasAnsweredQuestion) {
        this.hasForgottenToAnswer = true;
        return;
      }
      this.training.getNextQuestion();
      this.score = this.training.getScore();
      this.resetForNextQuestion();
      //this.$store.dispatch('training/update', this.training);
    },
    filter() {
      return this.techs.filter(item => {
        if (this.isKidsMode) {
          return item.degree.ordinal <= this.selectedDegreeType.ordinal && item.isSafeForKids();
        }
        return item.degree.ordinal <= this.selectedDegreeType.ordinal;
      });
    },
    restartTraining() {
      this.score = new Score(0, 0);
      this.resetForNextQuestion();
      this.training = new Training(answerAmount, this.filter());
      //this.$store.dispatch('training/update', this.training);
    },
    resetForNextQuestion() {
      this.answers = [];
      this.isHidden = true;
      this.isCorrect = false;
      this.hasAnsweredQuestion = false;
      this.hasForgottenToAnswer = false;
    }
  },
  mounted() {
    this.isKidsMode = this.$store.getters['training/isKidsMode'];
    this.isQuestionByFlashCard = this.$store.getters['training/isQuestionByFlashCard'];
    this.selectedDegreeType = this.$store.getters['training/selectedDegreeType'];
    this.traning = new Training(answerAmount, this.filter());
    //this.$store.dispatch('training/update', this.training);
  }
};
</script>
