/**
 * Enum for common Judo Techniques.
 * @readonly
 * @enum {{name: string, dutchName: string}}
 */
export const TechniqueType = Object.freeze({
	NONE: {name: '', dutchName: 'Alle'},
	ARM: {name: 'Te-waza', dutchName: 'Armworpen'},
	ARM_LOCK: {name: 'Kansetsu-waza', dutchName: 'Armklemmen'},
	CHOKE: {name: 'Shime-waza', dutchName: 'Verwurgingen'},
	HIP: {name: 'Koshi-waza', dutchName: 'Heupworpen'},
	LEG: {name: 'Ashi-waza', dutchName: 'Beenworpen'},
	PINNING: {name: 'Osaekomi-waza', dutchName: 'Houdgrepen'},
	SACRIFICE: {name: 'Sutemi-waza', dutchName: 'Opofferingsworpen'},
	SHOULDER: {name: 'Kata-waza', dutchName: 'Schouderworpen'},
});
