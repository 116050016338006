import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import training from '../store/modules/training';

Vue.use(Vuex);

const store = new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',
	modules: {
		training
	},
	plugins: [
		createPersistedState({
			key:'vuex-judo-app',
			paths: ['training']
		})
	]
});

export default store;
