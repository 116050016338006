import {TechniqueList} from '../judo/TechniqueList';
import {Question} from './Question';
import {Anwser} from './Anwser';
import {Score} from './Score';

export class Training {
	constructor(answerAmount, techList) {
		this.answerAmount = answerAmount;
		this.techList = techList;
		if (!Array.isArray(this.techList)) {
			this.techList = TechniqueList.getList();
		}
		this.questionList = [];
		this.question = this._findQuestionByTechList(this.techList);
		this.score = new Score(0,0);
	}

	getNextQuestion() {
		this.questionList.push(this.question);
		this.question = this._findQuestionByTechListWithoutLastQuestion(this.question, this.techList);
	}

	getScore() {
		const correct = this.questionList.filter(item => {
			return item.answeredCorrect === true;
		});

		const inCorrect = this.questionList.filter(item => {
			return item.answeredCorrect === false;
		});

		this.score = new Score(correct.length, inCorrect.length);
		return this.score;
	}

	_findAnswers(techList) {
		// When there are less techniques than answerAmount return this list as answers.
		if (techList.length < this.answerAmount) {
			return techList.map(technique => {
				return this._getIncorrectAnswerForTechnique(technique);
			});
		}

		let answers = {};
		// Find random answers, but one less than the given amount
		while (  Object.values(answers).length < this.answerAmount -1) {
			const answer = this._getInCorrectAnswer(techList);
			if (answers[answer.judoTechnique.name] === undefined ) {
				answers[answer.judoTechnique.name] = answer;
			}
		}

		return Object.values(answers);
	}

	/**
	 * Find a question from the tech list, but filer out last question to make sure
	 * we don't ask the same question in a row.
	 */
	_findQuestionByTechListWithoutLastQuestion(lastQuestion, techList) {
		const filteredTechList = this._filterOutQuestionAsAnswer(techList, lastQuestion.question);
		return this._findQuestionByTechList(filteredTechList);
	}

	/**
	 * Find a question from the tech list
	 */
	_findQuestionByTechList(techList) {
		const correctAnswer = this._getCorrectAnswer(techList);
		// filter out the question from the techList so we don't accidentally add
		// the same answer marked as incorrect as we have at the question.
		const filteredTechList = this._filterOutQuestionAsAnswer(techList, correctAnswer);
		// Make sure all answers are of the same technique type and not mixing pinning with legs,etc..
		const filteredSameTechList = this._filterOnSameTechniqueType(filteredTechList, correctAnswer);
		const filteredTechListForAnswers = filteredSameTechList.length > 1 ? filteredSameTechList : filteredTechList;
		return new Question(true, correctAnswer, this._findAnswers(filteredTechListForAnswers));
	}

	_filterOutQuestionAsAnswer(techList, answer) {
		return techList.filter(item => {
			return item.name.toLowerCase() !== answer.judoTechnique.name.toLowerCase()
		});
	}

	_filterOnSameTechniqueType(techList, answer) {
		return techList.filter(item => {
			return item.type === answer.judoTechnique.type
		});
	}

	_getInCorrectAnswer(techList) {
		return this._getAnswer(techList, false);
	}

	_getCorrectAnswer(techList) {
		return this._getAnswer(techList, true);
	}

	_getAnswer(techList, isCorrect) {
		let judoTechnique = this._getRandomEntryFromList(techList);
		return new Anwser(judoTechnique, isCorrect);
	}

	_getIncorrectAnswerForTechnique(judoTechnique) {
		return new Anwser(judoTechnique, false);
	}

	_getRandomEntryFromList(techList) {
		let judoTechnique = techList[Math.floor(Math.random() * techList.length)];
		if (judoTechnique.image === null) {
			return this._getRandomEntryFromList(techList);
		}
		return judoTechnique;
	}
}
