import {Media} from './Media';
import {MediaType} from './MediaType';

export class Movie extends Media {

	constructor(uri, isPrimary, aspectRatio) {
		super(uri, MediaType.MOVIE, aspectRatio, isPrimary);
	}

}
