import {Image} from './Image';

export class GoogleDriveImage extends Image {

	constructor(uri, driveImageId, isPrimary, aspectRatio) {
		super(uri, isPrimary, aspectRatio);
		this.driveImageId = driveImageId;
	}

	getImageUri() {
		return this.uri;
		/*		if (this.driveImageId === '') {
					return this.uri;
				}
				return 'https://drive.google.com/uc?export=view&id=' + this.driveImageId;*/
	}
}
