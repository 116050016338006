<template>
  <div class="content">
      <br />
      <div class="columns notification">
        <div class="column">
          <h1>Instructie judo app</h1>

          <p>
            Zoals jullie weten is het heel erg belangrijk dat je op het examen, de namen van de technieken in het Japans weet.<br/>
            Op deze pagina vind je de uitleg van de judo app.
            Met behulp van de app kunnen jullie de namen van de technieken oefenen.
          </p>
        </div>
      </div>

      <div>&nbsp;</div>

      <div class="columns notification ">
        <div class="column">
          <h2>Judo technieken</h2>

          <p>
            Hier vind je alle foto’s en filmpjes van alle technieken. Op deze pagina staan een paar knoppen, welke hieronder uitgelegd worden.
          </p>
          <ul>
            <li><strong>Techniek type</strong> <br />
              Klik hier om bijvoorbeeld alleen de beenworpen of armworpen te zien.
            </li>
            <li><strong>Kleur band</strong> <br /> Klik de band aan waarvoor je examen gaat doen. <br />
                                             Voorbeeld: Als je in het bezit bent van een <strong>gele band</strong> dan doe je examen voor de <strong>oranje band</strong>. <br />
                                             Deze knop kan ook gecombineerd worden met de <strong>Techniek type</strong> knop
              <br />
            </li>
            <li><strong>YouTube logo </strong> <br />
              Als je op het YouTube logo drukt kan je heel het filmpje zien van de techniek.
              <br />
            </li>
          </ul>

        </div>
      </div>

    <div>&nbsp;</div>

      <div class="columns notification">
        <div class="column">
          <h2>Judo toets</h2>

          <p>
            De naam zegt al genoeg hier test je, je kennis over de technieken en probeer je een zo hoog mogelijke score te behalen tijdens het maken van de toets. <br/>
            Als je de judo toets knop hebt aangeklikt start de toets meteen. Aan de toets is geen tijd gebonden, kijk rustig naar de vraag en probeer deze goed te beantwoorden. <br/>
            Linksboven in het scherm staat kleur band hier selecteer je de kleur band waarvoor je examen gaat doen. <br />
            Het systeem zal automatisch de technieken sorteren die je moet weten voor je examen.
          </p>

          <ul>
            <li><strong>Kids modus</strong> <br />
              Als je de kids modus aanzet zullen alle verwurgingen, armklemmen & offerworpen niet gevraagd worden.
              De judoka’s die 12 jaar en ouder zijn zetten de kids modus uit.
            </li>
            <li><strong>Vragen modus</strong> <br />
              Als deze knop uitstaat dan krijg je de foto van een worp te zien en zal jij hier de juiste naam bij moeten kiezen. <br />
              Wanneer je deze knop aanzet verandert de toets en krijg je de naam van de worp te zien, maar zal jij de juiste foto bij de naam van de worp moeten kiezen.
            </li>
          </ul>

          <p>
            Onder de antwoorden vind je de knop volgende om naar de volgende vraag te gaan. <br /> Als je vergeten bent een antwoord te geven op de vraag,
            dan krijg je hier melding van en kan je nog niet door naar de volgende vraag. <br />

            <br />
            Heel veel plezier en succes met het oefenen van de namen.
          </p>
        </div>
      </div>
    </div>
</template>

<script>

	export default {
		name: 'About',
		data() {
			return {

			};
		},
		computed: {

		},
		methods: {

		}
	};
</script>
