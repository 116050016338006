
export class Score {

	constructor(totalCorrect, totalInCorrect) {
		this.totalCorrect = totalCorrect;
		this.totalInCorrect = totalInCorrect;
	}

	getTotal() {
		return this.totalCorrect + this.totalInCorrect;
	}
}
