/**
 * Enum for AspecRatio.
 * @readonly
 * @enum {{name: string, bulamaRatio: 'class name' }
 */
export const AspectRatio = Object.freeze({
	SIXTEEN_BY_NINE: {name: '16:9', bulmaRatio: 'is-16by9'},
	FOUR_BY_THREE: {name: '4:3', bulmaRatio: 'is-4by3'},
	TWO_BY_THREE: {name: '2:3', bulmaRatio: 'is-2by3'},
});
