<template>
  <div class="card" v-bind:style="{ backgroundColor: color}">
    <div class="card-media has-ratio">
      <template v-if="judoTechnique.getImageList().length > 0">
        <b-carousel :key=judoTechnique.name
                    :autoplay="autoplay"
                    :has-drag="true"
                    :indicator="true"
                    :indicator-background="true"
                    :indicator-inside="true"
                    :value=carousel>
          <b-carousel-item v-for="(image, i) in judoTechnique.getImageList()"
                           :key="i"
                           @click.native="onCheckAnswer">

            <figure :class="image.getBulmaAspectRationClass()" class="image">
              <img :src="image.getImageUri()" class="image">
            </figure>

          </b-carousel-item>
        </b-carousel>
      </template>
      <template v-else>
        <figure class="image">
          <img :alt="judoTechnique.name"
               :src="judoTechnique.getPrimaryImageUri()"
               class="image is-128x128">
        </figure>
      </template>
    </div>
    <footer class="card-footer" >
      <p class="card-footer-item" v-if="!isHidden">
        <span v-if="isCorrect"
              class="icon has-text-success"
              style="background-color: white; border-radius: 25px;">
                    <em class="fas fa-check-square"></em>
        </span>
        <span v-else
              class="icon has-text-danger"
              style="background-color: white; border-radius: 25px;">
                    <em class="fas fa-ban"></em>
        </span>
      </p>
      <p class="card-footer-item" v-else>&nbsp;</p>
    </footer>
  </div>

</template>

<script>

export default {
  name: 'TrainingCarousel',
  props: [
    'autoplay',
    'judoTechnique',
    'isCorrect',
    'isHidden',
    'cardColor'
  ],
  data() {
    return {
      carousel: 0,
    };
  },
  computed: {
    color() {
      if (this.cardColor === 'is-success') {
        return '#23d160';
      }
      else if(this.cardColor === 'is-danger') {
        return '#ff3860';
      }
      return 'whitesmoke';
    }
  },
  methods: {
    onCheckAnswer() {
      this.$emit('onCheckAnswer');
    }
  }
};
</script>
