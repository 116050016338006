<template>
	<div class="content">
		<div class="columns is-mobile">
			<div class="column">
				<b-dropdown aria-role="list" hoverable v-model="selectedTechType">
					<button class="button is-info" slot="trigger">
						<span v-if="selectedTechType === techniqueTypes.NONE">Techniek type</span>
						<span v-else>{{ selectedTechType.dutchName }}</span>
						<b-icon icon="menu-down"></b-icon>
					</button>

					<b-dropdown-item :key="index"
					                 :value="option"
					                 aria-role="listitem"
					                 v-for="(option, index) in techniqueTypes">

						{{ option.dutchName }}
					</b-dropdown-item>
				</b-dropdown>

				<b-dropdown aria-role="list" hoverable v-model="selectedDegreeType">
					<button class="button is-info" slot="trigger">
						<span v-if="selectedDegreeType === degreeTypes.NONE">Kleur band</span>
						<span v-else>{{ selectedDegreeType.colour }} ({{ selectedDegreeType.degree }})</span>
						<b-icon icon="menu-down"></b-icon>
					</button>

					<b-dropdown-item :key="index"
					                 :value="option"
					                 aria-role="listitem"
					                 v-for="(option, index) in degreeTypes">

						<template v-if="option.ordinal < 100">
							{{ option.colour }} ({{ option.degree }})
						</template>
						<template v-else>
							{{ option.name }}
						</template>
					</b-dropdown-item>
				</b-dropdown>
			</div>
		</div>
		<div class="columns is-mobile is-multiline is-centered " v-if="filteredItems.length > 0">
			<div :key=tech.name
			     class="column is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-third-fullhd"
			     v-for="(tech, index) in filteredItems">

				<div class="card" style="background-color: whitesmoke">
					<header :style="tech.hasMovie() ? 'cursor: pointer;' : ''"
					        :title="tech.hasMovie() ? 'Bekijk de worp op youtube' : 'Deze worp staat nog niet op youtube'"
					        @click="openModal(tech)"
					        class="card-header">

						<p class="card-header-title">
							<img :src="'assets/images/youtube.png'"
							     :style="(tech.hasMovie() ? '' : 'filter: grayscale(100%);')"
							     alt="worp op Youtube"
							     class="image is-32x32"
							     style="margin: 0 10px 0 0;">
							{{ tech.name }}
						</p>
						<p class="card-header-title is-centered "  v-if="tech.hasMovie()">

						</p>
						<p class="card-header-icon">
							{{ tech.dutchName }}
						</p>
					</header>
					<div class="card-image has-ratio">
						<template v-if="tech.getImageList().length > 0">
							<b-carousel :autoplay="true"
							            :has-drag="true"
							            :indicator="true"
							            :indicator-background="true"
							            :indicator-inside="true"
							            :key=index
							            :value=carousel>
								<b-carousel-item :key="i" v-for="(image, i) in tech.getImageList()">
									<figure :class="image.getBulmaAspectRationClass()" class="image">
										<img :alt="tech.name"
										     :src="image.getImageUri()"
										     :title="tech.name + ' - ' + tech.dutchName"
										     class="image">
									</figure>
								</b-carousel-item>
							</b-carousel>
						</template>
						<template v-else>
							<figure class="image">
								<img :alt="tech.name"
								     :src="'assets/images/' + tech.getImage()"
								     :title="tech.name + ' - ' + tech.dutchName"
								     class="image is-128x128">
							</figure>
						</template>
					</div>
					<footer class="card-footer">
						<p class="card-footer-item">
					      <span>
					        {{ tech.type.dutchName }}
					      </span>
						</p>
					</footer>
				</div>
			</div>
		</div>

		<div class="columns is-mobile is-multiline is-centered" v-else>
			Geen technieken gevonden.
		</div>

		<b-modal :active.sync="isImageModalActive">
			<p class="image is-16by9">
				<iframe :src="'https://www.youtube.com/embed/' + movieId"
				        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
				        class="has-ratio"
				        height="315"
				        title="youtube"
				        width="560">
				</iframe>
			</p>
		</b-modal>

	</div>
</template>

<script>
	import {Degree} from '../../assets/js/judo/Degree';
	import {TechniqueList} from '../../assets/js/judo/TechniqueList';
	import {TechniqueType} from '../../assets/js/judo/TechniqueType';

	export default {
		name: 'TechniqueOverview',
		data() {
			return {
				carousel: 0,
				isImageModalActive: false,
				movieId: '',
				selectedTechType: TechniqueType.NONE,
				selectedDegreeType: Degree.NONE,
				techs: TechniqueList.getList(),
				carousels: []
			};
		},
		computed: {
			filteredItems() {
				return this.filterTechs();
			},
			techniqueTypes() {
				return TechniqueType;
			},
			degreeTypes() {
				return Degree;
			}
		},
		methods: {
			openModal(tech) {
				if (tech.hasMovie()) {
					this.movieId = tech.getFirstMovie().uri;
					this.isImageModalActive = true;
				}
			},
			filterTechs() {
				// only show techniques that has items in imageList.
				return this.techs.filter(item => {
					// if NONE is selected return all items with at least 1 image in the list.
					if (this.selectedTechType === TechniqueType.NONE) {
						return item.degree.ordinal <= this.selectedDegreeType.ordinal && item.getImageList().length > 0;
					}
					return item.type.dutchName.toLowerCase().indexOf(this.selectedTechType.dutchName.toLowerCase()) > -1 &&
						item.degree.ordinal <= this.selectedDegreeType.ordinal && item.getImageList().length > 0;
				});
			}
		}
	};
</script>
