import {Degree} from '../../js/judo/Degree';

const training = {
	namespaced: true,

	state: () => ({
		isKidsMode: false,
		isQuestionByFlashCard: false,
		selectedDegreeType: Degree.NONE,
		training: {}
	}),

	getters: {
		training: state => {
			return state.score;
		},
		isKidsMode: state => {
			return state.isKidsMode;
		},
		isQuestionByFlashCard: state => {
			return state.isQuestionByFlashCard;
		},
		selectedDegreeType: state => {
			return state.selectedDegreeType;
		}
	},

	mutations: {
		update(state, training) {
			state.training = training;
		},
		updateKidsMode(state, isKidsMode) {
			state.isKidsMode = isKidsMode;
		},
		updateQuestionByFlashCard(state, isQuestionByFlashCard) {
			state.isQuestionByFlashCard = isQuestionByFlashCard;
		},
		updateSelectedDegreeType(state, selectedDegreeType) {
			state.selectedDegreeType = selectedDegreeType;
		}
	},

	actions: {
		update({commit}, training) {
			commit('update', training);
		},
		updateKidsMode({commit}, isKidsMode) {
			commit('updateKidsMode', isKidsMode);
		},
		updateQuestionByFlashCard({commit}, isQuestionByFlashCard) {
			commit('updateQuestionByFlashCard', isQuestionByFlashCard);
		},
		updateSelectedDegreeType({commit}, selectedDegreeType) {
			commit('updateSelectedDegreeType', selectedDegreeType);
		}
	},

};

export default training;
