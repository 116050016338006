import TechniqueOverview from './assets/components/TechniqueOverview';
import Router from 'vue-router';
import TrainingOverview from './assets/components/TrainingOverview';
import About from './assets/components/About';

const RM_INDEX = '/techniques';

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			redirect: RM_INDEX
		},
		{
			path: '/techniques',
			name: 'techniques',
			component: TechniqueOverview
		},
		{
			path: '/training',
			name: 'training',
			component: TrainingOverview
		},
		{
			path: '/about',
			name: 'about',
			component: About
		},
	]
});

export default router;
