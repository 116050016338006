export class Question {
	constructor(isQuestionByImage, answerForQuestion, anwsers) {
		this.isQuestionByImage = isQuestionByImage;
		this.question = answerForQuestion;
		if (!Array.isArray(anwsers)) {
			anwsers = [];
		}
		this.answers = anwsers;
		this.answeredCorrect = false;
	}

	getAnswers() {
		return [this.question, ...this.answers];
	}
}
